<template>
     <div class="mainWrapper pb-0">
        <AppLoader v-if="loader" />
        <section class="blockElement space position-relative reatesPage" v-if="show && store.flavordata.flavorId">
            <div class="container">
                <vue-pdf-embed :source="`${static_vars.domainURL}assets/pdf/legal/Declaration_of_Consent_ZULUTRADE.pdf`" v-show="!store.flavordata?.isEu" @loaded="Load" />
                <vue-pdf-embed :source="`${static_vars.domainURL}assets/pdf/legal/Declaration_of_Consent_ZULUTRADE.pdf`" v-show="store.flavordata?.isEu" @loaded="Load" />
                </div>
        </section>
    </div>
</template>
<script>
import { myStore } from "@/store/pinia-store";
import VuePdfEmbed from 'vue-pdf-embed'
export default {
    setup() {
        const store = myStore();
        return { store };
    },
  data() {
    return {
        show : false,
        loader : false
    };
  },
  components : {
    VuePdfEmbed
  },
  methods:{
    Load(e){
        if(e){
            this.loader = false
        }
    }
  },
  mounted(){
    setTimeout(()=> {
        this.show = true
    },1000)
  },
  created(){
    this.loader = true
  }
};
</script>